#root {
	--mui-button-background: linear-gradient(126.25deg,
			#efb961 3.77%,
			#fa6460 88.31%);
	--mui-button-border-radius: 24px;
}

.pintura-editor {
	--editor-max-width: 31em;
	--editor-max-height: 31em;
	opacity: 0.95;
	background-color: transparent;
}

@font-face {
	font-family: "Lexend";
	src: url("./fonts/Lexend-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

.drag-drop-bound {
	height: 80vh;
}

.row {
	display: flex;

}
 * {
	font-family: Lexend !important;
 }
 body {

	font-size: 16px !important;
}

body,
.MuiTypography-root,
.MuiButton-root {
	font-family: Lexend !important;

}

span {
	font-family: Lexend !important;
}

.MuiButton-root {
	text-transform: unset !important;
}

.MuiButton-root h6 {
	padding-right: 10px;
}

.MuiButtonBase-root,
.MuiMenuItem-root {
	font-family: "Lexend" !important;
	font-size: 16px !important;
	font-weight: 500;
}

.MuiInput-root {
	font-family: "Lexend" !important;

	font-weight: 500;
}

.MuiAlert-standardSuccess,
.MuiSnackbar-root,
.MuiAlert-root {
	padding: 0px 16px !important;
}

.MuiAlert-message {
	overflow: visible !important;
	white-space: normal !important;
}

.MuiInputBase-input,
.MuiInput-input,
.MuiOutlinedInput-root,
.MuiOutlinedInput-input {
	background-color: "#F8F8F8" !important;
	font-style: "normal" !important;

	font-size: 1rem !important;
	line-height: 20px !important;
	font-weight: 500 !important;
}

/*

.MuiInput-input {
	padding: 10px 14px !important;
}

.MuiInputBase-input {
	padding: 10px 14px !important;
}
*/
.MuiInputBase-input.MuiOutlinedInput-input:not(textarea) {
	padding: 8.5px 14px !important;
}

#multiple-limit-tags input,
#multiple-limit-tags1 input {
	height: 40px !important;
}

.row {
	display: flex !important;
	flex-direction: row !important;

}

.column {
	display: flex !important;
	flex-direction: column !important;
}

.left-align {
	display: flex !important;
	flex-direction: row !important;
	justify-content: left !important;
}

.right-align {
	display: flex !important;
	flex-direction: row !important;
	justify-content: right !important;
}

.center-align {
	display: flex !important;
	justify-content: center !important;
	align-content: center !important;
	align-items: center !important;
}

.container {
	width: 100%;
	/* height:100%; */
	/* height: 100vh; */
	height: auto;
	display: flex;
}

.logoSearchBtn img {
	padding-right: 2rem;
}

.left-side {
	background-color: #1d2d53;
	background-repeat: no-repeat;
	background-position: bottom;
	flex: 1;
	height: 100%;
}

.left-panel-background-image {
	background-size: "cover";
}

.right-side {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding-bottom: 10px;
	/* height: 100%; */
}

.top-link {
	margin: 24px;
	align-self: flex-end;
}

.inner-box {
	display: flex;
	width: 50%;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	align-self: center;
}

.mx-2 {
	margin-top: "6px";
	margin-bottom: "6px";
}

.MuiDialogTitle-root .MuiTypography-root {
	font-size: 1.5rem;
}

.settings {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.sliderText {
	align-self: start !important;
	font-family: Lexend !important;
	color: #7A7985 !important;
	font-size: 1rem !important;
	font-weight: 400 !important;
}

.sliderInput {
	height: 40px !important;

}

.settings-menu {
	width: 25%;
}

.settings-content {
	width: 50%;
	background-color: aqua;
}

.cancel-button {
	/* background: #FFFFFF !important; */
	text-transform: none !important;

}

.cancel-button p{
	color: #FF702A !important;
}

.edit-button {
	font-size: 1rem !important;
	font-weight: 500 !important;
	width: 126px !important;
}

.cancel-edit-button {
	color: #ff702a !important;
	width: 104px !important;
	border-color: #ff702a !important;
	border-radius: 18px !important;
	font-size: 1rem !important;
	font-weight: 500 !important;
}

.cancel-edit-button:hover {
	border: 2px solid #ff702a !important;
}

.settings-alert {
	margin-left: 25%;
	margin-right: 25%;
}

.connection-procedure {
	margin-top: 25px;
}

.css-epd502 {
	margin: 5px !important;
	background: #ffffff;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
	border-radius: 12px;
}

.help-session {
	top: 5%;
	position: relative;
}

.jamsession-label {
	font-size: 1rem !important;
	color: #7a7985 !important;
	font-weight: 400 !important;
	margin: 10px 0 9px 0 !important;
	font-family: "Lexend" !important;
}


.MuiButton-endIcon {
	position: absolute;
	right: 5%;
}

.Mui-disabled {
	color: #707085 !important;
}

.heading-name {
	text-align: center;
}

.edit-heading-name {
	text-align: center;
	font-style: normal;
	font-size: 36px !important;
	font-weight: 500 !important;
}

.left-align-heading-name {
	text-align: left;
	font-style: normal;
	font-size: 36px;
	font-weight: 500;
}

.password-validate {
	display: flex;
	align-items: flex-start;
	padding: 8px 0;
}

.signuplink {
	/* background: #eaeaea !important; */
	text-transform: none !important;
	color: #0273e6 !important;
	margin: 10px !important;
	border-color: #0273e6 !important;
	border-radius: var(--mui-button-border-radius) !important;
}

.password-content {
	display: flex;
}

/* #edit-helperText {
  height: 20px;
  padding-bottom: 30px;
} */

.change-password-guideline {
	font-style: normal;
	font-weight: 500;
	text-align: left;
	color: #000000;
	margin-top: 60px;
}

.outlined-weight-helper-text {
	margin-left: 2px;
	margin-right: 0px;
	/* color: #707085 !important; */
}

.MuiFormHelperText-root {
	font-family: "Lexend" !important;
	font-style: normal !important;
	font-size: 1rem !important;
	/* color: #d32f2f; */
	/* text-align: right !important; */
}

.outerlabel {
	margin-left: 2px !important;
	margin-top: 24px;
	margin-bottom: 24px;
}

.signupform {
	width: 100%;
	display: flex;
	flex-direction: column !important;
	align-items: center;
}

.password-guidelines {
	display: flex;
	flex-direction: column;
	align-content: center;
	position: absolute;
	width: 200px;
	margin-top: 100px;
	/* margin-left: 30px; */
	left: 65%;
	text-align: left;
}

.password-guideline {
	margin: 0 24px;
	width: 300px;
	bottom: 120px;
	font-style: normal;
	font-size: 1rem;
	left: 45%;
	text-align: left;
	color: #707085;
}

.logo {
	position: absolute;
	top: 40px;
	left: 60px;
}

.image-container {
	flex: 5;
}

.MuiInput-input {
	font-family: Lexend !important;
}

.content {
	color: #ffffff;
	position: relative;
	width: 85%;
	height: 100px;
	left: 54px;
	top: 130px;
}

/* #username {
  width: 200px;
} */

label {
	font-family: "Lexend";
	font-style: normal;
	font-size: 1rem;
	color: #707085;
}

.submit-button {
	text-transform: none !important;
	color: #ffffff !important;
	padding: 8px 16px 8px 16px !important;
}

.submit-button p {
	text-transform: none !important;
	color: #ffffff !important;
	font-size: 1.125rem !important;
	display: inline-block;
	width: auto;
	margin-right: 18px;
}

.submit-button img {
	width: 1.5rem;
	height: 1.5rem;
}

.settings-check-availability {
	width: 240px !important;
	height: 55px !important;
	color: #0273e6 !important;
	background-color: #fff !important;
	border-color: #0273e6 !important;
}
.settings-check-availability.disabled {
    opacity: 0.5; /* example: reduce opacity when disabled */
    cursor: not-allowed; /* example: change cursor when disabled */
    /* Add any other styles you want to apply when the button is disabled */
}

.social-check-availability {
	color: #0273e6 !important;
	background-color: #fff !important;
	border-color: #0273e6 !important;
}
.social-check-availability.disabled {
	opacity: 0.5; /* example: reduce opacity when disabled */
    cursor: not-allowed;
}
.next-button {
	/* background: #1d2d53 !important; */
	text-transform: none !important;
	/* display: flex !important;
	justify-content: flex-end !important; */
	border-radius: var(--mui-button-border-radius) !important;
	background: var(--mui-button-background) !important;
}

.mixer-button {
	background: #000000 !important;
	text-transform: none !important;
	border: 1px solid #ffffff !important;
}

a {
	text-decoration: none !important;
}

.session-form {
	font-size: 1.125rem !important;
	font-weight: 900 !important;
	font-style: normal !important;
	line-height: 25px !important;
	color: #000000 !important;
}

.MuiTabs-indicator {
	height: 4px !important;
}

.MuiAlert-action {
	align-items: center !important;
}

.MuiAccordion-root.Mui-disabled {
	background-color: transparent !important;
}

/* Calender css */
/* .rmdp-range {
	background-color: #1d2d53 !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
	background-color: #1d2d53 !important;
}

.rmdp-arrow-container:hover {
	background-color: #1d2d53 !important;
}

.rmdp-day.rmdp-selected span:not(.highlight){
	background-color: #1d2d53 !important;
}
.rmdp-selected .sd {
	background-color: #1d2d53 !important;
} */

.rmdp-arrow {
	border: solid #292d32;
	border-width: 0 2px 2px 0;
}

.rmdp-arrow-container:hover {
	background-color: #ff702a;
}

.rmdp-day.rmdp-today span {
	background-color: #ff702a;
}

.rmdp-day:not(.rmdp-day-hidden) span:hover {
	background-color: #0074d9 !important;
}

.calender-custom {
	width: 98%;
}

.rmdp-wrapper {
	width: 100%;
}

.rmdp-top-class {
	display: block !important;
}

.rmdp-day-picker {
	display: block !important;
}

#video-container {
	/* grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	grid-template-rows: repeat(auto-fit, minmax(150px, 100%)); */

	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(auto-fit, minmax(150px, 100%));
	grid-auto-flow: row dense;
}

#video-container>div {
	padding: 0.5em;
	grid-column: span 2;
}

.box {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: #ccc;
	border: 1px solid black;
	text-align: center;
	padding: 10px;
	box-sizing: border-box;
	margin-bottom: 10px;
	overflow: hidden;
	position: relative;
	margin: 20px;
}

.box .text {
	text-align: center;
}

.hover-handles .react-resizable-handle {
	display: none;
}

.hover-handles:hover .react-resizable-handle {
	display: block;
}

.custom-box {
	overflow: visible;
}

.custom-handle {
	position: absolute;
	width: 8px;
	height: 8px;
	background-color: #1153aa;
	opacity: 0.75;
	border-radius: 4px;
}

.custom-handle-sw {
	bottom: -4px;
	left: -4px;
	cursor: sw-resize;
}

.custom-handle-se {
	bottom: -4px;
	right: -4px;
	cursor: se-resize;
}

.custom-handle-nw {
	top: -4px;
	left: -4px;
	cursor: nw-resize;
}

.custom-handle-ne {
	top: -4px;
	right: -4px;
	cursor: ne-resize;
}

.custom-handle-w,
.custom-handle-e {
	top: 50%;
	margin-top: -4px;
	cursor: ew-resize;
}

.custom-handle-w {
	left: -4px;
}

.custom-handle-e {
	right: -4px;
}

.custom-handle-n,
.custom-handle-s {
	left: 50%;
	margin-left: -4px;
	cursor: ns-resize;
}

.custom-handle-n {
	top: -4px;
}

.custom-handle-s {
	bottom: -4px;
}

video {
	width: 100% !important;
	height: 100% !important;
}

#demo-simple-select {
	padding: 5px !important;
}

.video-grid-container {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 5fr;
}

.local-video {
	grid-row: 1 / 2;
}

.remote-video-grid {
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	grid-template-rows: repeat(auto-fill, minmax(200px, 1fr));
	grid-row: 2 / 3;
	display: grid;
}

.remote-video {
	width: 100%;
	height: 100%;
}

/* .volume-slider {
	position: relative;
	display: flex;
	width: 3%;
	height: 40%;
	top: 50%;
	left: 2%;
	flex-direction: column;
	align-items: center;
	background: rgba(0, 0, 0, 0.35);
	border-radius: 6px;
	padding: 10px 0 0 0;
} */

.slider {
	height: 100%;
	margin-bottom: 10px;
	margin-left: 2px;
}

.slider-div {
	height: 100%;
	margin-bottom: 10px;
	margin-left: 2px;
}

.emoji {
	z-index: 1000;
	position: absolute;
	font-size: 50px;
	width: 100%;
	display: flex;
	justify-content: center;
	bottom: 10px;
	transform: scale(1);
	animation: crescendo 1.5s alternate infinite ease-in;
}

@keyframes crescendo {
	0% {
		transform: scale(0.8);
	}

	100% {
		transform: scale(1.5);
	}
}

.mixer-container {
	position: relative;
	display: grid;
	height: 100%;
	/* // gridTemplateColumns: `100%  1fr`; */
	grid-template-rows: 100%;
	gap: 26px;
	grid-template-columns: repeat(6, 1fr);
	padding: 30px 24px;
	overflow: auto;
}

@media (max-height: 670px) {
	.mixer-container {
	  padding: 10px 24px 16px 24px; 
	}
  }
  

.mixer-footer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 24px;
	position: relative;
}

.mute-button {
	background: #000000 !important;
	text-transform: none !important;
	float: right;
}

.active-menu {
	border-bottom: 4px solid #fff069;
}

.messageNotificationIcon {
	background: #a1c3f5;
	color: #000000;
	/* height: 25px;
	width: 30px; */
	border-radius: 20px;
	text-align: center;
	padding: 5px 5px;
	margin: 0 10px;
}

.jamsession-divider {
	border: 1.5px solid #888888 !important;
	border-bottom-width: 36px !important;
}

.MuiCardContent-root {
	padding-bottom: 8px !important;
}

.verticallyCenter {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.verticallyCenterUserCode {
	display: flex;
	align-items: center;
}

.verticallyCenterUserCode img {
	margin-right: 4%;
}

.planContent ul{
	list-style: none;
	padding:0;
}
.planContent ul li{
	list-style: none;
	line-height: 24px;
	margin-bottom: 4px;
	padding: 0px;
}
.planContent ul li:first-child:not(:has(svg)) span {
    margin-left: -17px;
}
.planContent .css-cveggr-MuiListItemIcon-root{
	min-width:35px;
}
.planContent ul li div{
	min-width: 35px;
}

/* removed css */
/* @media only screen and (min-width: 1501px)  and (max-width: 2000px) { */
@media only screen and (min-width: 1201px) and (max-width: 1400px) {
	/* #left-panel-title-custom-size {
		font-size: 1.8rem !important;
	} */

	.screen-title-custom-size {
		font-size: 2rem !important;
	}

	.signin-title-custom-size {
		font-size: 3rem !important;
	}

	.logo-custom-height {
		height: 70px;
	}

	#back-image-height {
		height: 350px;
	}

	.typography-field-custom-font-size {
		font-size: 1rem !important;
	}

	.attendee-field-custom-font-size {
		font-size: 0.9rem !important;
	}

	.helper-content-custom-font-size {
		font-size: 0.9rem !important;
	}

	.appbar-logo-custom-height {
		height: 60px;
	}

	.home-title-custom-size {
		font-size: 2rem !important;
	}

	.menu-label-typography-custom-font-size {
		font-size: 1.2rem !important;
	}

	.session-name-typography-custom-font-size {
		font-size: 1.2rem !important;
	}

	.session-desc-typography-custom-font-size {
		font-size: 1.2rem !important;
	}

	.music-logo-custom-height {
		height: 20px;
	}

	.profile-custom-height {
		height: 120px;
	}

	.sessions-custom-height {
		overflow-y: scroll;
		max-height: 70vh;
		padding-bottom: 2;
	}

	.card-title-custom-size {
		font-size: 1.4rem !important;
	}

	.MuiPickersPopper-paper {
		transform: scale(0.7) !important;
	}

	.tab-custom-font-size {
		font-size: 1.2rem !important;
	}

	.security-content-custom-size {
		font-size: 1.1rem !important;
	}

	.dialog-title-custom-size {
		font-size: 2rem !important;
	}

	.custom-padding {
		padding: 16px;
	}
}

@media only screen and (min-width: 1401px) and (max-width: 2000px) {
	/* #left-panel-title-custom-size {
		font-size: 2.4rem !important;
	} */

	.screen-title-custom-size {
		font-size: 2.3rem !important;
	}

	.signin-title-custom-size {
		font-size: 4rem !important;
	}

	.logo-custom-height {
		height: 100px;
	}

	#back-image-height {
		height: 400px;
	}

	.typography-field-custom-font-size {
		font-size: 1.1rem !important;
	}

	.attendee-field-custom-font-size {
		font-size: 0.9rem !important;
	}

	.helper-content-custom-font-size {
		font-size: 0.9rem !important;
	}

	.appbar-logo-custom-height {
		height: 50px;
	}

	.home-title-custom-size {
		font-size: 2.25rem !important;
	}

	.menu-label-typography-custom-font-size {
		font-size: 1.1rem !important;
	}

	.session-name-typography-custom-font-size {
		font-size: 1.1rem !important;
	}

	.session-desc-typography-custom-font-size {
		font-size: 1.1rem !important;
	}

	.music-logo-custom-height {
		height: 22px;
	}

	.profile-custom-height {
		height: 140px;
	}

	.sessions-custom-height {
		overflow-y: scroll;
		max-height: 80vh;
		padding-bottom: 2;
	}

	.card-title-custom-size {
		font-size: 1.5rem !important;
	}

	.tab-custom-font-size {
		font-size: 1.2rem !important;
	}

	.security-content-custom-size {
		font-size: 1.1rem !important;
	}

	.dialog-title-custom-size {
		font-size: 2.3rem !important;
	}

	.custom-padding {
		padding: 24px;
	}
}

@media only screen and (min-width: 2001px) {
	/* #left-panel-title-custom-size {
		font-size: 3rem !important;
	} */

	.screen-title-custom-size {
		font-size: 4rem !important;
	}

	.signin-title-custom-size {
		font-size: 4rem !important;
	}

	.logo-custom-height {
		height: 120px;
	}

	#back-image-height {
		height: 500px;
	}

	.typography-field-custom-font-size {
		font-size: 1.3rem !important;
	}

	.attendee-field-custom-font-size {
		font-size: 1rem !important;
	}

	.helper-content-custom-font-size {
		font-size: 1rem !important;
	}

	.appbar-logo-custom-height {
		height: 60px;
	}

	.home-title-custom-size {
		font-size: 2.8rem !important;
	}

	.menu-label-typography-custom-font-size {
		font-size: 2rem !important;
	}

	.session-name-typography-custom-font-size {
		font-size: 1.9rem !important;
	}

	.session-desc-typography-custom-font-size {
		font-size: 1.8rem !important;
	}

	.music-logo-custom-height {
		height: 22px;
	}

	.profile-custom-height {
		height: 160px;
	}

	.sessions-custom-height {
		overflow-y: scroll;
		max-height: 90vh;
		padding-bottom: 2;
	}

	.card-title-custom-size {
		font-size: 2.2rem !important;
	}

	.tab-custom-font-size {
		font-size: 1.4rem !important;
	}

	.security-content-custom-size {
		font-size: 1.4rem !important;
	}

	.dialog-title-custom-size {
		font-size: 1.5rem;
	}

	.custom-padding {
		padding: 24px;
	}
}

.invitation-card-title-wrapper {
	display: flex;
	align-items: center;
	gap: 10px;
}

.cover-container {
	position: "relative";
	width: "100%";
	padding-top: "calc(100% / (16/9))";
}

.cover-image {
	position: "absolute";
	top: 0;
	left: 0;
	width: "100%";
	height: "100%";
	object-fit: "cover";
}

.disabled {
	pointer-events: none;
	opacity: 0.5;
}

.notification-time {
	font-size: 0.875rem;
	font-weight: 500;
	color: #7a7985;
	display: flex;
	justify-content: flex-end;
	margin-right: 3px;
}

.statCard .dispVal {
	font-size: 3.75rem !important;
	font-weight: 400;
	line-height: 75px;
	letter-spacing: 0em;
	text-align: center;
}

.inActIconBtn {
	display: "flex";
	align-items: center;
	justify-content: "center";
}

.inActUserContainer {
	margin-top: 27px;
}

.signinWidth {
	width: 324px;
}

.homeTitle {
	width: 74% !important;
	flex-basis: 74% !important;
}

.scheduleBtn {
	max-width: 288px !important;
}

/*
.userCardShorts {
	display: flex;
	flex-direction: column !important;
	border: 1px solid #cfd9df;
}

 .profilePic {
	max-width: 20.7% !important;
}

.musicianDetails {
	max-width: 49% !important;
} */

.coverPhoto {
	border-radius: "20px";
	position: "absolute";
	width: "642px";
	height: "441px";
	left: "40px";
	top: "40%";
}

#outlined-adornment-password,
#username {
	padding: 8.5px 14px !important;
}

.musicCardProf {
	height: "160px";
	width: "160px";
}

.colorOrange {
	color: #ff702a !important;
}

.colorOrange p {
	color: #ff702a !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	padding-left: 0px !important;
}

.leftPanelWidth {
	flex-basis: 288px !important;
	max-width: 288px !important;
	margin-right: 20px !important;
}

.centerPanelWidth {
	flex-basis: 600px !important;
	max-width: 600px !important;

}

.centerPanelwoRight {
	flex-basis: 914px !important;
	max-width: 914px !important;
	margin: 0px !important;
	padding: 0px !important;

}

.centerPanelSocial {
	flex-basis: 100% !important;
	max-width: 914px !important;
	margin: 0px !important;
	padding: 0px !important;
}

.rightPanelWidth {
	flex-basis: 288px !important;
	max-width: 288px !important;
	margin-left: 10px !important;
}

.rightPanelWidthAdmin {

	margin-left: 25px !important;
	margin-top: -20px !important;
}

.centerPanelwoRightEvent {
	flex-basis: 912px !important;
	max-width: 912px !important;
}

.rightPanelWidthEvent {
	flex-basis: 288px !important;
	max-width: 288px !important;
	margin-left: 24px !important;
}

.eventThumbnail {
	width: 198px !important;
	height: 198px !important;
}

.gigFilter input {
	width: 223px !important;
	flex-basis: 223px !important;
	max-width: 223px !important;
}

.leftPanelGig {
	flex-basis: 291px !important;
	max-width: 291px !important;
	margin-right: 20px !important;
}

.centerPanelwoRightGig {
	flex-basis: 911px !important;
	max-width: 911px !important;
	margin: 0px !important;
	padding: 0px !important;

}

.filterLabel {
	font-size: 1rem !important;
	color: #7a7985 !important;
	font-weight: 400 !important;
	margin: 24px 0 0 0 !important;
	font-family: "Lexend" !important;
}

p {
	word-break: break-word;
}

.horizonCardWidth {
	width: 858px;
}

#globalInput {
	color: #313131;
	font-size: 16px;
	font-weight: 500;
	padding-left: 20px;
	width: 273px;
	height: 36px;
	border-radius: 18px;
	border: 1px solid #C6C4C4;
	background-color: #EDF5FF;
	padding-right: 40px;
	box-sizing: border-box;
}

#globalInput:focus {
	outline: none !important;
}

.input-options {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 25px;
	background-color: #fff;
	padding: 0 10px;
}

.input-options:hover {
	cursor: pointer;
	background-color: #F0F0F0 !important;
}

.options-width {
	max-width: 120px;
	overflow: hidden;
	white-space: nowrap;
	font-size: 1rem;
	font-weight: 500;
	color: "#313131";
	text-overflow: ellipsis;
}

.category-global {
	color: #7A7985;
	font-size: 0.875rem;
	font-weight: 400;
}


.rmdp-calendar {
	padding: 20px !important;
	background: #ffffff;
	box-shadow: 0px 1px 16px 1px rgba(217, 231, 255, 0.77);
	border-radius: 12px;
}

.rmdp-header {
	font-size: 0.971rem !important;
}

.rmdp-header-values {
	font-size: 0.971rem !important;
	font-weight: 700 !important;
}

.rmdp-day {
	font-size: 0.8125rem !important;
}

.rmdp-week-day {
	font-size: 0.8125rem !important;
	color: black;
}

.only.rmdp-month-picker {
	width: auto;
}

.appBarTabs .iconButton {
	padding: 9px 12px !important;
}

.appBarIconBtn {
	padding: 8px !important;
}

.mainPanelSession {
	width: 100% !important;
	margin: 0 auto;
	overflow: hidden !important;
}

.mainPanel {
	width: 1224px !important;
	margin: 0 auto;
}

.infinite-scroll-component__outerdiv {
	width: 100%;
}

.sessionIconBtn {
	margin: 0 8px !important;
}

.sessionTitle {
	width: 140px;
}

.sessionSelect {
	min-width: 200px;
}
.avtarUpload{
	max-width: 150px;
	margin: 0 10px;
	text-align: center;
}

/* Add some margin to the tag */
.custom-tag {
	margin-right: 5px; /* Adjust as needed */
  }

  /* Adjust the position of the clear and popup indicators */
  .custom-autocomplete .MuiAutocomplete-endAdornment {
	margin-left: 10px; /* Adjust as needed */
  }
  .fliter .MuiAutocomplete-root .MuiOutlinedInput-root {
	padding-right:55px;
  }

  .loginLeft{
	min-height:20vh;
  }

  .planHeight{
	height:445px;
	width: 288px;
}

/* responsive old code*/
@media only screen and (min-width: 485px) and (max-width: 900px) {
	/* #left-panel-title-custom-size {
		font-size: 1.2rem !important;
	} */
#app-header{
	min-width:1224px !important;
}
	.screen-title-custom-size {
		font-size: 1.4rem !important;
	}

	.signin-title-custom-size {
		font-size: 1.9rem !important;
	}

	.logo-custom-height {
		height: 50px;
	}

	#back-image-height {
		height: 250px;
	}



	.attendee-field-custom-font-size {
		font-size: 0.5rem !important;
	}

	.helper-content-custom-font-size {
		font-size: 0.5rem !important;
	}

	.button-field-custom-font-size {
		font-size: 0.6rem !important;
	}

	.responsive-outline-input-size {
		padding: 0 !important;
	}

	.appbar-logo-custom-height {
		height: 40px;
	}

	.home-title-custom-size {
		font-size: 1.6rem !important;
	}

	.menu-label-typography-custom-font-size {
		font-size: 0.9rem !important;
	}

	.music-logo-custom-height {
		height: 16px;
	}

	.profile-custom-height {
		height: 100px;
	}

	.sessions-custom-height {
		overflow-y: scroll;
		max-height: 220px;
		padding-bottom: 2;
	}

	.card-title-custom-size {
		font-size: 1rem !important;
	}

	.rmdp-calendar {
		padding: 3px !important;
		background: #ffffff;
		box-shadow: 0px 1px 16px 1px rgba(217, 231, 255, 0.77);
		border-radius: 12px;
	}

	.rmdp-header {
		font-size: 1rem !important;
	}

	.rmdp-header-values {
		font-size: 1rem !important;
		font-weight: 700 !important;
	}

	.rmdp-day {
		font-size: 1rem !important;
	}

	.rmdp-week-day {
		font-size: 0.7rem !important;
		color: black;
	}

	.rmdp-day span {
		font-size: 0.7rem !important;
	}

	.tab-custom-font-size {
		font-size: 1rem !important;
	}

	.security-content-custom-size {
		font-size: 1.1rem !important;
	}

	.dialog-title-custom-size {
		font-size: 1.5rem;
	}

	.custom-padding {
		padding: 0;
	}
}

/* @media only screen and (min-width: 1001px)  and (max-width: 1500px) { */
@media only screen and (min-width: 901px) and (max-width: 1000px) {
	/* #left-panel-title-custom-size {
		font-size: 1.6rem !important;
	} */
	#app-header{
		min-width:1224px !important;
	}
	.appBarTabs .MuiButtonBase-root-MuiTab-root {
		width: max-content;
		padding: 0;
	}

	.screen-title-custom-size {
		font-size: 1.8rem !important;
	}

	.signin-title-custom-size {
		font-size: 3rem !important;
	}

	.logo-custom-height {
		height: 60px;
	}

	#back-image-height {
		height: 300px;
	}

	.typography-field-custom-font-size {
		font-size: 0.7rem !important;
	}

	.attendee-field-custom-font-size {
		font-size: 0.9rem !important;
	}

	.helper-content-custom-font-size {
		font-size: 0.8rem !important;
	}

	.button-field-custom-font-size {
		font-size: 0.6rem !important;
	}

	.appbar-logo-custom-height {
		height: 40px;
	}

	.home-title-custom-size {
		font-size: 1.8rem !important;
	}

	.menu-label-typography-custom-font-size {
		font-size: 1rem !important;
	}

	.session-name-typography-custom-font-size {
		font-size: 0.9rem !important;
	}

	.session-desc-typography-custom-font-size {
		font-size: 0.8rem !important;
	}

	.music-logo-custom-height {
		height: 18px;
	}

	.profile-custom-height {
		height: 120px;
	}

	.sessions-custom-height {
		overflow-y: scroll;
		max-height: 60vh;
		padding-bottom: 2;
	}

	.card-title-custom-size {
		font-size: 1.2rem !important;
	}

	.rmdp-calendar {
		padding: 5px !important;
		background: #ffffff;
		box-shadow: 0px 1px 16px 1px rgba(217, 231, 255, 0.77);
		border-radius: 12px;
	}

	.rmdp-header {
		font-size: 1rem !important;
	}

	.rmdp-header-values {
		font-size: 1rem !important;
		font-weight: 700 !important;
	}

	.rmdp-day {
		font-size: 1rem !important;
	}

	.rmdp-week-day {
		font-size: 0.7rem !important;
		color: black;
	}

	.rmdp-day span {
		font-size: 0.7rem !important;
	}

	.only.rmdp-month-picker {
		width: auto;
	}

	.tab-custom-font-size {
		font-size: 1rem !important;
	}

	.MuiPickersPopper-paper {
		transform: scale(0.7) !important;
	}

	.security-content-custom-size {
		font-size: 1rem !important;
	}

	.dialog-title-custom-size {
		font-size: 1.9rem !important;
	}

	.custom-padding {
		padding: 8px;
	}
}

/* Responsive  */

#app-header{
	min-width:1224px !important;
}

@media only screen and (min-width: 1226px) and (max-width: 2000px) {
	.mainPanel {
		width: 1224px !important;
		margin: 0 auto;
	}

	.appBarCont {
		max-width: 1224px !important;
		padding: 8px 0 !important;
	}

	.scheduleBtn {
		max-width: 288px !important;
	}

}

@media only screen and (min-device-width: 1022px) and (max-device-width: 1150px) and (orientation: portrait), only screen
and (min-device-width: 1024px)
and (max-device-width: 1366px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2)  {
		#app-header{
		min-width:100% !important;
	}

	/* only screen and (min-device-width: 1024px) and (max-device-width: 1150x) and (orientation: landscape) { */
	/* Your styles for iPad Pro in landscape mode */
.hideInTabPro{ display: none;}
.alignCenterTabLogin{
	margin: auto 0 !important;
}
.loginLeft{
	min-height:0vh;
  }
  .tabImageLogin{
	margin: auto 0 !important;
  }
	body {
		padding-right: 0 !important;
		overflow-x: hidden !important;
		overflow-y: auto !important;
	}

	.rightPanelWidth {
		display: none !important;
	}

	.AppBarContainer {
		display: block;
	}

	.appBarCont {
		width: 100vw !important;
		padding: 8px 0 !important;
	}

	.logoSearchBtn img {
		padding-right: 0.5rem;
		display: none;
	}

	.logoSearchBtn div div {
		padding: 8px 10px;
	}

	.logoSearchBtn input {
		width: 50px !important;
		padding: 8px 0px 8px 8px !important;
	}

	.appBarIconBtn {
		padding: 8px !important;
	}

	.rightButton {
		position: relative;
		left: -13px;
	}

	.appTabs {
		margin-left: -5px;
	}

	.scheduleBtn {
		max-width: 288px !important;
	}

	.statCard {
		width: 14rem !important;
	}

	.statCard div {
		padding: 0px !important;
	}

	.statCard h6 {
		font-size: 1.3rem !important;
	}

	.statCard .dispVal {
		font-size: 3.5rem !important;
	}

	.userManagTitle {
		font-size: 1.7rem !important;
	}

	.userFilter {
		max-width: 23% !important;
		flex-basis: 23% !important;
		margin-right: 15px !important;
	}

	.userManagTable {
		max-width: 72% !important;
		flex-basis: 72% !important;
	}

	.musicEventContainer {
		display: flex !important;
		flex-direction: column !important;
	}

	.musicEventGrid {
		max-width: 100% !important;
	}

	.musicianBtns {
		padding-left: 2%;
	}

	.musicCardProf {
		height: "120px" !important;
		width: "120px" !important;
	}

	.profilePic {
		max-width: "17%" !important;
		flex-basis: "17%" !important;
	}

	.instruSkill {
		max-width: 40% !important;
		flex-basis: 40% !important;
	}

	.center-align>div:first-child {
		margin: 0 auto;
		width: 100%
	}

	.mainPanel {
		max-width: 1010px !important;
		flex-basis: 1010px !important;
		margin: 0 !important;

	}

	.leftPanelWidth {
		flex-basis: 288px !important;
		max-width: 288px !important;
		margin-right: 16px !important;
		margin-left: 16px !important;
	}

	.centerPanelWidth {
		flex-basis: 675px !important;
		max-width: 675px !important;
	}

	.rightPanelWidth {
		display: none !important;
	}

	.centerPanelwoRightGig {
		flex-basis: 675px !important;
		max-width: 675px !important;
		margin: 0px !important;
		padding: 0px !important;
	}

	.centerPanelwoRight {
		flex-basis: 690px !important;
		max-width: 690px !important;
		margin: 0px !important;
		padding: 0px !important;

	}

	.centerPanelwoRightEvent {
		flex-basis: 688px !important;
		max-width: 688px !important;
		margin: 0px !important;
		padding: 0px !important;
	}

	.horizonCardWidth {
		width: 607px;
	}

	.horiCenterContent {
		padding-left: 38px !important;
	}

	.iconButton {
		display: flex !important;
		flex-direction: column !important;
		max-height: 50px !important;
		padding: 12px 10px !important;
	}

	.appBarTabs {
		padding-left: 10px;
	}

	.iconButton img {
		margin-right: 0px !important;
	}

	.appBarTabs .MuiTabs-flexContainer button:nth-child(4) {
		position: relative;
		left: -5px;
	}

	.appBarTabs .MuiTabs-flexContainer button:nth-child(5) {
		position: relative;
		left: -20px;
	}

	#globalInput {
		width: 265px;
	}

	.centerPanelSocial {
		flex-basis: 690px !important;
		max-width: 690px !important;
		margin: 0px !important;
		padding: 0px !important;
	}

	.leftPanelGig {
		flex-basis: 291px !important;
		max-width: 291px !important;
		margin-right: 20px !important;
		margin-left: 10px !important;
	}

	.tabSocialHead {
		margin-left: 15px;
	}

	.centerPanelSocialHome {
		flex-basis: 695px !important;
		max-width: 695px !important;
		margin: 0px !important;
		padding: 0px !important;
	}

	.musicianDetails {
		max-width: 270px !important;
		flex-basis: 270px !important;
	}

	.sessionIconBtn {
		margin: 0 5px !important;
	}

	.sessionTitle {
		width: 70px;
	}

	.sessionMenu {
		padding: 0 10px !important;
	}

	.sessionSelect {
		min-width: 150px;
	}
	.avtarUpload{
		max-width: 150px;
		margin: 0 4px;
		text-align:center;
	}
	.listAudioPlayers{
		width: 200px !important;
		max-width:200px !important
	}

.password-guidelines {

	margin-top: 130px;
	/* margin-left: 30px; */
	left: 75%;
}

.planContent ul{
	list-style: none;
	padding:0;
}
.planContent ul li{
	list-style: none;
	line-height: 24px;
	margin-bottom: 4px;
	padding: 0px;
}
.planContent ul li:first-child:not(:has(svg)) span {
    margin-left: -25px;
}
.planContent .css-cveggr-MuiListItemIcon-root{
	min-width:25px;
}

.planHeight{
	height:490px;
	width: 220px;
}

}


@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
	/* Your styles for iPad Pro 12 in landscape mode go here */
	#app-header{
		min-width:100% !important;
	}
	.mainPanel {
		width: 1224px !important;
		margin: 0 auto !important;
	}

.centerPanelwoRight {
	flex-basis: 914px !important;
	max-width: 914px !important;
	margin:0px !important;
	padding:0px !important;
	width:914px !important;
}
.centerPanelwoRightGig {
	flex-basis: 911px !important;
	max-width: 911px !important;
	margin:0px !important;
	padding:0px !important;
	width:911px !important;
}

.sessionIconBtn{
	margin: 0 10px !important;
}
.alignCenterTabLogin{
	margin: auto 0 !important;
}
.loginLeft{
	min-height:0vh;
  }

}

.dialogWidthEvent .MuiDialog-paper{
	max-width:808px !important;
	width:808px !important;
}

.dialogWidthEvent .MuiInputBase-adornedEnd .MuiOutlinedInput-input{
	padding: 8.5px 0 8.5px 12px !important;
}
.dialogWidthEvent .MuiIconButton-edgeEnd{
	padding-left:0px !important;
}
.w10 {
	width: 10% !important;
	flex-basis: 10% !important;
}

.w12 {
	width: 12% !important;
	flex-basis: 12% !important;
}

.w14 {
	width: 14% !important;
	flex-basis: 14% !important;
}

.w16 {
	width: 16% !important;
	flex-basis: 16% !important;
}

.w18 {
	width: 18% !important;
	flex-basis: 18% !important;
}

::-ms-reveal {
    display: none;
}

audio::-webkit-media-controls-panel{
	background-color:#F5F5F5 !important;
	}
	audio{
		height:35px !important;
		border-radius: 30px !important;
		background: #fff !important;
		color:grey;
		width:250px !important;
	}
	  * {
		scrollbar-width: thin !important;
	  }
	  .controlsContainer, #controlBar{
		background-color: #F5F5F5 !important;
		color:#000000;
	}
	  audio::-webkit-media-controls-volume-slider, audio::-moz-media-controls-volume-slider, #volumeControl  { display: none !important; }

.signup-or {
	font-size: 20px !important;
	font-weight: 500 !important;
	line-height: 25px !important;
	text-align: center;
	margin-top: 20px !important;
	margin-bottom: 20px !important;
}

.signup-google-apple-btn {
	border-radius: 24px !important;
	height: 48px !important;
	width: 350px;
	border: 2px solid #0273E6 !important;
	font-size: 18px !important;
	color: #0273E6 !important;
	display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    padding-left: 60px !important;
}

.center-align-row {
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-content: center !important;
	align-items: center !important;
}

.signup-confirm-message {
	font-size: 20px !important;
	font-weight: 500 !important;
	line-height: 25px !important;
}

.signup-links-span {
	font-size: 18px !important;
	font-weight: 400 !important;
	line-height: 22.5px !important;
}

.signup-links-span-new {
	font-size: 16px !important;
	font-weight: 400 !important;
	line-height: 20px !important;
}


.sticky-header {
	position: sticky;
	top: 0;
	z-index: 1000;
	background-color: #ffffff;
}

.social-signup-title {
	font-size: 18px !important;
	font-weight: 600 !important;
	line-height: 22.5px !important;
	text-align: center;
}

.social-signup-span {
	font-size: 14px !important;
	font-weight: 300 !important;
	line-height: 19.6px !important;
	color: #5C5C5C !important;
	text-align: end;
	margin-top: -23px !important;
}


.MuiPaper-root.MuiDialog-paper {
	border-radius: 12px !important;
}

.wrapTextTitle{
	text-wrap: wrap !important;
}

.recharts-legend-wrapper{
	margin-top: -15px;
}


.custom-calendar {
	width: 288px;
	height: 232px;
  }

  .subButton:disabled{
	/* //background: #7A7985; */
	background: linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%);
	color: #999;
	padding: 8px 18px;
  }
  .subButton {
	background: linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%);
	padding: 8px 18px;
  }

  .reCancelStorageBtn {
	
																color: #fff;
																border-radius: 24px;
																padding: 10px 20px;
																text-transform: none;
																cursor: pointer;
																opacity: 1;
																pointer-events: auto;
																width: 209px;
																height: auto;
																line-height: 1.25;
  }
  .reCancelStorageBtn:disabled{
	color: #999;
  }
  
/* For daily Test */
.spinner {
	border: 4px solid #f3f3f3;
	border-top: 4px solid #ff702a;
	border-radius: 50%;
	width: 12px;
	height: 12px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.fade-out {
	animation: fadeOut 0.5s ease forwards;
}

.slide-up {
	animation: slideUp 0.5s ease forwards;
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes slideUp {
	from {
		transform: translateY(20px);
		opacity: 0;
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
}
/* For daily Test Ends */