.primarySlider{
    height: 100%;
}
.secondarySlider{
    height: 100%;
}
.secondarySliderContainer{
    padding-top: 24px;
}
.mixerImg{
    height: 15px;
    width: 15px;
}
.secondaryText{
    width:50px;
}
.showIconButton{
    padding-top: 8px;
    /* height:20px;
    width: 20px;
    background-color: #CFD9DF; */
}

.presetText {
    font-size: 0.6rem !important;
    color: #C5C5C5 !important;
    line-height: 16.39px !important;
    font-weight: 500 !important;
    margin-bottom: 3px !important;
}

@media (max-height: 670px) {
    .presetText {
        font-size: 0.56rem !important;
    }
  }
  

.centerAligned {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}


.preset-dropdown {
    background-color: #000;
    border: 1px solid #62688A;
    border-radius: 6px;
    color: #CFD9DF;
    padding: 4px 10px;
    appearance: none;
    /* Removes the default arrow */
    background-image: url('../../icons/arrow-down.svg');
    /* Custom arrow */
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px 12px;
    /* Adjust arrow size as needed */
    width: 100%;
    font-size: 14px !important;
}

/* Ensure border color remains the same on focus/selection */
.preset-dropdown:focus {
    border-color: #62688A;
    outline: none;
    /* Remove default outline */
}

.preset-input {
    width: 100%;
    font-size: 16px;
    margin-top: 8px;
    border-radius: 6px;
    border: 1px solid #C5C5C5;
    background-color: #F8F8F8;
    color: black;
    font-weight: 500;
    line-height: 20px;
    height: 36px !important;
    width: 154px !important;
}

.preset-input:focus {
    border-color: #C5C5C5;
    outline: none;
}

.presetIcons {
    text-align: center;
    cursor: pointer;
}

.disabledImage {
    pointer-events: none;
    cursor: not-allowed;
}

@media only screen and (min-width: 901px) and (max-width: 1200px){
    /* .primarySlider{
        height: 50%;
    }
    .secondarySlider{
        height: 56%;
    } */
    .secondarySliderContainer{
        padding-top: 8px;
        margin-top: 42px;
    }
    .secondaryText{
        width:30px;
    }
    /* .primarySliderContainer{
        margin-top: 31px;
    } */
    .showIconButton{
        padding-top: 0px;
    }
    
    /* to secondary block height and check on paddings */
}

@media only screen and (min-width: 1201px) and (max-width: 1300px){
    /* .primarySlider{
        height: 50%;
    }
    .secondarySlider{
        height: 68%;
    } */
    .secondarySliderContainer{
        padding-top: 8px;
        margin-top: 42px;
    }
    .secondaryText{
        width:40px;
    }
    
    /* todo secondary block height check on paddings */
}
@media only screen and (min-width: 1301px) and (max-width: 1400px){
    /* .primarySlider{
        height: 70%;
    } */
}

@media only screen and (min-width: 1401px) and (max-width: 2000px){}

@media only screen and (min-width: 2001px){}